<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div :class="`delete-modal ${size}`">
        <div>
          <h1 class="text-base heading">Export projects to Excel</h1>

          <div class="mx-8 my-5">
            <TextInput
              ref="selectCluster"
              :label="'Cluster'"
              :types="'select'"
              :options="data.clusters"
              @input="
                (e) => {
                  selectedCluster = e;
                  $refs.selectSector.value = null;
                  selectedSector = null;
                }
              "
              :defaultValue="data.cluster"
            />
            <TextInput
              ref="selectSector"
              :label="'Sector'"
              :types="'select'"
              :options="data.sectors"
              @input="
                (e) => {
                  selectedSector = e;
                }
              "
              :defaultValue="data.sector"
            />
            <TextInput
              :label="'Select year'"
              :not="true"
              :types="'select'"
              :defaultValue="data.year"
              @input="(e) => (selectedYear = e)"
              :options="data.years"
            />

            <TextInput
              ref="selectStatus"
              :label="'Status'"
              :types="'select'"
              :options="[
                ...data.statuses,
                ...(selectedType === 'CABINET_PAPER'
                  ? [
                      { name: 'SENT_BACK', id: 'SENT_BACK' },
                      { name: 'UNDER_ANALYSIS', id: 'UNDER_ANALYSIS' },
                      { name: 'APPROVED', id: 'APPROVED' },
                    ]
                  : []),
              ]"
              @input="(e) => (selectedStatus = e)"
              :defaultValue="data.status"
            />

            <TextInput
              :label="'Type'"
              :types="'select'"
              :options="data.types"
              @input="
                (e) => {
                  selectedType = e;
                  if (e === 'CABINET_PAPER') {
                    // reload statuses
                    $refs.selectStatus.value = null;
                  }
                }
              "
              :defaultValue="data.type"
            />
          </div>

          <div class="block sm:flex mx-8">
            <div>
              <button
                :style="`backgroundColor: ${abort}`"
                class="rounded w-40 mt-8 ml-8 text-base outline-none font-extralight"
                @click="close()"
              >
                Abort
              </button>
            </div>
            <div>
              <button
                :style="`backgroundColor:${deleteBg} `"
                class="rounded w-40 mt-8 ml-8 text-base outline-none font-extralight"
                @click="change()"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import TextInput from "../form/TextInput.vue";
export default {
  name: "ExportProjectsModal",
  components: {
    TextInput,
  },
  data() {
    return {
      msg: "",
      hasError: false,
      abort: "#B6C0C9",
      deleteBg: "#28A4E2",
      selectedCluster: null,
      selectedSector: null,
      selectedYear: null,
      selectedStatus: null,
      selectedType: null,
    };
  },
  props: {
    size: {
      type: String,
      default: "big",
    },
    data: {
      type: Object,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setPeriod(value) {
      this.data.period = value;
    },
    change() {
      this.$emit("export", {
        cluster: this.selectedCluster,
        sector: this.selectedSector,
        year: this.selectedYear,
        status: this.selectedStatus,
        type: this.selectedType,
      });
    },
  },
  created() {
    this.selectedCluster = this.data.cluster;
    this.selectedSector = this.data.sector;
    this.selectedYear = this.data.year;
    this.selectedStatus = this.data.status;
    this.selectedType = this.data.type;
  },
};
</script>
