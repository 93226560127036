<template>
  <div id="appointmentDetails" class="px-4 lg:px-12">
    <div class="row">
      <div class="col-12 title">
        {{
          `${project ? "View" : "Create new"}  ${
            $route.path.includes("pcas")
              ? "PCA"
              : $route.path.includes("priority-projects")
              ? "Priority Project"
              : $route.path.includes("presidential-pledges")
              ? "Presidential pledge"
              : $route.path.includes("pmo-meeting")
              ? "PMO meeting"
              : "Cabinet Paper"
          }`
        }}
      </div>
      <div v-if="project" class="tabs">
        <div
          :class="`tab cursor-pointer ${!activeTab ? 'active' : ''}`"
          @click="activeTab = 0"
        >
          Project details
        </div>
        <div
          v-if="
            project
              ? project.status == 'SUBMITTED'
                ? $store.state.auth.user.roles[0].name !== 'ADMIN' &&
                  $store.state.auth.user.projectRoles &&
                  ($store.state.auth.user.projectRoles.status == 'ANALYST'
                    ? canAnalystsCompleteProjects
                    : true)
                : $store.state.auth.user.roles[0].name == 'ADMIN'
              : false
          "
          :class="`tab cursor-pointer ${activeTab == 1 ? 'active' : ''}`"
          @click="activeTab = 1"
        >
          {{
            $store.state.auth.user.projectRoles &&
            ["SUPERVISOR", "VIEWER"].includes(
              $store.state.auth.user.projectRoles.status
            ) &&
            project.createdBy != $store.state.auth.user.id
              ? "Add comment"
              : "Change status"
          }}
        </div>
        <div
          v-if="
            ($store.state.auth.user.projectRoles &&
              $store.state.auth.user.projectRoles.status !== 'VIEWER') ||
            $store.state.auth.user.roles[0].name == 'ADMIN'
          "
          :class="`tab cursor-pointer ${activeTab == 2 ? 'active' : ''}`"
          @click="activeTab = 2"
        >
          History
        </div>
      </div>
      <div v-show="activeTab == 2" class="appointment-details">
        <div class="md:flex">
          <div class="summary">
            <div class="title flex place-items-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12H4C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4C9.25 4 6.824 5.387 5.385 7.5H8V9.5H2V3.5H4V6C5.824 3.57 8.729 2 12 2ZM13 7V11.585L16.243 14.828L14.828 16.243L11 12.413V7H13Z"
                  fill="#7C8197"
                />
              </svg>
              Project history
            </div>
            <!-- <Search
              class="hidden"
              label="Search by title, comment"
              @input="getValue"
              :isGrey="true"
            /> -->
            <div class="timeline custom-scroll">
              <TimeLineItem
                v-for="(item, i) in project ? project.projectTimelines : []"
                :key="i"
                :title="''"
                :subtitle="`Updated By ${item.userProjectRole.user.firstName} ${item.userProjectRole.user.lastName}`"
                :date="item.createdAt"
                :user="item.user"
                :active="item == selected_timeline"
                :file="item.file"
                @click.native="
                  selected_timeline = item;
                  activeTab1 = 0;
                "
                :id="`timeline-${item.id}`"
              />
            </div>
          </div>
          <div
            v-if="selected_timeline"
            class="detailed w-full custom-scroll md:pl-11 mt-12"
          >
            <div class="flex">
              <div
                class="image"
                v-if="selected_timeline.user.imageUrl"
                :style="`
            background: url(${selected_timeline.user.imageUrl});
            background-position: center;
            background-size: cover;`"
              />
              <Avatar
                v-else
                :fullname="
                  `${selected_timeline.user.firstName}` +
                  ' ' +
                  `${selected_timeline.user.lastName}`
                "
                :size="42"
                color="#054D6F"
                class="mt-1 sm:mt-2 image"
              ></Avatar>
              <div class="ml-2">
                <div class="date">
                  {{ selected_timeline.createdAt | timeAgo }}
                </div>
                <div class="total_comments">
                  {{ selected_timeline.comments.length }} comments
                </div>
              </div>
            </div>
            <div v-if="project" class="tabs">
              <div
                :class="`tab cursor-pointer ${!activeTab1 ? 'active' : ''}`"
                @click="activeTab1 = 0"
              >
                Details
              </div>
              <div
                :class="`tab cursor-pointer ${activeTab1 == 1 ? 'active' : ''}`"
                @click="activeTab1 = 1"
              >
                Comments
              </div>
            </div>
            <div v-if="!activeTab1" class="mt-4">
              <div class="w-full text-left sectiony">
                <div class="heading">Sector</div>
                <div class="description capitalize">
                  {{ selected_timeline.sector.name }}
                </div>
              </div>
              <div class="w-full text-left sectiony">
                <div class="heading">Action taken</div>
                <div class="description">
                  {{ selected_timeline.priorityProject }}
                </div>
              </div>
              <div class="w-full text-left sectiony">
                <div class="heading">Target</div>
                <div class="description">
                  {{ selected_timeline.target }}
                </div>
              </div>
              <div class="w-full text-left sectiony">
                <div class="heading">Institutions</div>
                <div class="description">
                  {{
                    selected_timeline.institutions.map((x) => x.name).join(",")
                  }}
                </div>
              </div>
              <div class="w-full text-left sectiony">
                <div class="heading">Timeline</div>
                <div class="description">
                  {{ selected_timeline.timeline }}
                </div>
              </div>
              <div class="w-full text-left sectiony">
                <div class="heading">Progress</div>
                <div class="description">
                  {{ selected_timeline.summaryReport }}
                </div>
              </div>
              <div class="w-full text-left sectiony">
                <div class="heading">Status</div>
                <div class="description">
                  {{ selected_timeline.status }}
                </div>
              </div>
              <div
                v-if="selected_timeline.file"
                class="w-full text-left sectiony"
              >
                <div class="heading">Attachment</div>
                <div
                  class="button flex justify-center place-items-center outlined"
                  @click="download(selected_timeline.file.url)"
                >
                  <img
                    v-if="selected_timeline.file"
                    :src="`/icons/${
                      selected_timeline.file.name.includes('zip')
                        ? 'zip.svg'
                        : selected_timeline.file.name.includes('doc') ||
                          selected_timeline.file.name.includes('docx')
                        ? 'docx.png'
                        : selected_timeline.file.name.includes('pdf')
                        ? 'pdf.png'
                        : selected_timeline.file.name.includes('xlsx')
                        ? 'xlsx.png'
                        : selected_timeline.file.name.includes('pptx') ||
                          selected_timeline.file.name.includes('ppt')
                        ? 'pptx.png'
                        : ''
                    }`"
                    alt=""
                    class="mt-1.5 w-6 cursor-pointer mr-2"
                  />

                  Download
                </div>
              </div>
            </div>
            <div v-else class="flex h-3/4">
              <div class="borders relative">
                <div
                  v-for="i in selected_timeline.comments.length"
                  :key="i"
                  class="border-div flex justify-center"
                >
                  <svg
                    v-if="i > 1"
                    width="20"
                    height="179"
                    viewBox="0 0 20 179"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="169"
                      r="9.5"
                      fill="#F2F6FA"
                      stroke="#EAF1F8"
                    />
                    <circle cx="9.66602" cy="169.333" r="5" fill="#DEDEED" />
                    <line
                      x1="11"
                      x2="11"
                      y2="154"
                      stroke="#E3E7EB"
                      stroke-width="2"
                    />
                  </svg>

                  <svg
                    v-else
                    width="20"
                    height="122"
                    viewBox="0 0 20 122"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="112"
                      r="9.5"
                      fill="#F2F6FA"
                      stroke="#EAF1F8"
                    />
                    <circle cx="9.66602" cy="112.333" r="5" fill="#DEDEED" />
                    <line
                      x1="10.502"
                      y1="0.996582"
                      x2="10.502"
                      y2="97.0066"
                      stroke="#E3E7EB"
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </div>
              <div class="messages">
                <TimeCommentItem
                  v-for="(item, i) in selected_timeline.comments"
                  :key="i"
                  :names="`${item.userProjectRole.user.firstName} ${item.userProjectRole.user.lastName}`"
                  :date_time="item.createdAt"
                  :content="item.comment"
                  :isLast="i == selected_timeline.comments.length - 1"
                  :id="`timeline-comment-${item.id}`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="activeTab == 1" class="appointment-details">
        <Form>
          <template v-slot:details>
            <div class="">
              <div class="w-full">
                <TextInput
                  v-if="
                    $store.state.auth.user.projectRoles
                      ? ['SUPERVISOR', 'ANALYST'].includes(
                          $store.state.auth.user.projectRoles.status
                        )
                      : $store.state.auth.user.roles[0].name == 'ADMIN'
                  "
                  :label="'Status'"
                  @input="
                    (value) => {
                      projectInfo.status = value;
                    }
                  "
                  :types="'select'"
                  :defaultValue="project && project.status"
                  :options="[
                    { name: 'SUBMITTED', id: 'SUBMITTED' },
                    ...($route.path.includes('papers')
                      ? [
                          { name: 'SENT BACK', id: 'SENT_BACK' },
                          { name: 'UNDER ANALYSIS', id: 'UNDER_ANALYSIS' },
                          { name: 'APPROVED', id: 'APPROVED' },
                        ]
                      : []),
                    { name: 'COMPLETED', id: 'COMPLETED' },
                  ]"
                  :not="true"
                />
                <TextInput
                  v-if="
                    $store.state.auth.user.projectRoles
                      ? ['SUPERVISOR', 'VIEWER'].includes(
                          $store.state.auth.user.projectRoles.status
                        ) &&
                        project.createdBy != $store.state.auth.user.id &&
                        project.status == 'SUBMITTED'
                      : false
                  "
                  :label="'Comment'"
                  @input="
                    (e) => {
                      projectInfo.comment = e;
                    }
                  "
                  :types="'textarea'"
                  :not="true"
                  :rows="6"
                  :max="true"
                />
              </div>
            </div>
            <Button
              :first-l="'Cancel'"
              :last-l="'Save'"
              @cancel="$emit('close')"
              @action="saveCommentOrStatus"
              :hasCancel="true"
            />
          </template>
        </Form>
      </div>
      <div v-show="!activeTab" class="user-details">
        <div class="md:flex margin_bottom">
          <div class="image">
            <vue-fullscreen
              v-if="
                (project && project.user.hasProfile) ||
                $store.state.auth.user.hasProfile
              "
              ref="fullscreen"
              v-model="fullscreen"
            >
              <img
                @click="toggle"
                :src="`http://minicaaf.primature.gov.rw/tmsbackend/api/v1/appointee/load-file/profile/${
                  (project && project.user.id) || $store.state.auth.user.id
                }`"
                alt=""
                :class="{ full: fullscreen }"
                class="mx-auto"
              />
            </vue-fullscreen>
            <Avatar
              v-else
              color="#054D6F"
              :size="130"
              class="mt-1 sm:mt-2 avatar cursor-pointer"
              id="prof-avatar"
              :fullname="
                project && project.user
                  ? project &&
                    project.user.firstName + ' ' + project.user.lastName
                  : $store.state.auth.user.firstName +
                    ' ' +
                    $store.state.auth.user.lastName
              "
            >
            </Avatar>
            <div class="text">
              {{
                project && project.user
                  ? project &&
                    project.user.firstName + " " + project.user.lastName
                  : $store.state.auth.user.firstName +
                    " " +
                    $store.state.auth.user.lastName
              }}
            </div>
            <div class="text" style="margin-top: 5px">
              {{
                project && project.user
                  ? project && project.user.email
                  : $store.state.auth.user.email
              }}
            </div>
            <div class="text" style="margin-top: 5px; font-weight: bold">
              {{
                project && project.user
                  ? project && project.user.mobile
                  : $store.state.auth.user.mobile
              }}
            </div>
          </div>
          <div class="details md:mx-16 mt-4 md:mt-0">
            <div>
              <div
                v-if="
                  $store.state.auth.user.projectRoles
                    ? ['SUPERVISOR', 'ANALYST'].includes(
                        $store.state.auth.user.projectRoles.status
                      ) &&
                      (project
                        ? project.status != 'COMPLETED' &&
                          project.createdBy == $store.state.auth.user.id
                        : true)
                    : $store.state.auth.user.roles[0].name == 'ADMIN'
                "
              >
                <Form>
                  <template v-slot:details>
                    <div class="md:flex md:space-x-12 block space-x-0">
                      <div class="flex-1">
                        <TextInput
                          ref="selectCluster"
                          :label="'Cluster'"
                          :types="'select'"
                          :options="clusters"
                          @input="
                            (e) => {
                              selectedCluster = e;
                              $refs.selectSector.value = null;
                              projectInfo.sector = null;
                              projectInfo.institutions = [];
                              selectedSector = null;
                            }
                          "
                          :defaultValue="
                            project
                              ? project.sector.cluster
                                ? project.sector.cluster.id
                                : ''
                              : ''
                          "
                        />
                        <TextInput
                          ref="selectSector"
                          :label="'Sector'"
                          :types="'select'"
                          :options="
                            sectors.filter(
                              (e) =>
                                selectedCluster == null ||
                                e.id == 'new' ||
                                e.cluster.id == selectedCluster
                            )
                          "
                          @input="
                            (e) => {
                              if (e == 'new') {
                                selectedSector = {
                                  name: '',
                                  cluster: { id: selectedCluster },
                                  clusterId: selectedCluster,
                                };
                              } else {
                                projectInfo.sector = e;
                              }
                            }
                          "
                          :defaultValue="
                            project ? project.sector.id : defaultSector
                          "
                        />
                        <TextInput
                          :label="'Target'"
                          @input="
                            (e) => {
                              projectInfo.target = e;
                            }
                          "
                          :types="'textarea'"
                          :rows="3"
                          :defaultValue="project && project.target"
                          :not="true"
                        />
                        <TextInput
                          :label="'Action taken'"
                          @input="
                            (e) => {
                              projectInfo.priorityProject = e;
                            }
                          "
                          :types="'textarea'"
                          :rows="3"
                          :defaultValue="project && project.priorityProject"
                        />
                        <DragAndDrop
                          :label="'File'"
                          :allowZip="true"
                          @input="setFile"
                          :not="true"
                          :cvPath="project && project.file && project.file.name"
                        />
                      </div>
                      <div class="flex-1">
                        <TextInput
                          :label="'Meeting Date'"
                          :types="'date'"
                          @input="
                            (e) => {
                              projectInfo.meetingDate = e;
                            }
                          "
                          :disabled="
                            project
                              ? $store.state.auth.user.roles[0].name == 'ADMIN'
                              : false
                          "
                          :defaultValue="project && project.meetingDate"
                        />
                        <TextInput
                          :label="'Timeline (will be continous if not selected)'"
                          :types="'date'"
                          @input="
                            (e) => {
                              projectInfo.timeline = e;
                            }
                          "
                          :disabled="
                            project
                              ? $store.state.auth.user.roles[0].name == 'ADMIN'
                              : false
                          "
                          :defaultValue="project && project.timeline"
                          :not="true"
                        />
                        <TextInput :label="'Institutions'" :types="'custom'">
                          <template slot="custom">
                            <multiselect
                              v-model="projectInfo.institutions"
                              :options="
                                institutions.filter(
                                  (e) =>
                                    selectedCluster == null ||
                                    e.cluster.id == selectedCluster
                                )
                              "
                              :multiple="true"
                              :close-on-select="false"
                              :clear-on-select="false"
                              :preserve-search="false"
                              placeholder="Institutions"
                              label="name"
                              track-by="id"
                              class="multi-select mb-7"
                            />
                          </template>
                        </TextInput>
                        <TextInput
                          v-if="$route.path.includes('papers')"
                          ref="selectStatus"
                          :label="'Status'"
                          :types="'select'"
                          :options="[
                            { name: 'Submitted', id: 'SUBMITTED' },
                            { name: 'Sent Back', id: 'SENT_BACK' },
                            { name: 'Under Analysis', id: 'UNDER_ANALYSIS' },
                            { name: 'Approved', id: 'APPROVED' },
                          ]"
                          @input="
                            (e) => {
                              projectInfo.status=e
                            }
                          "
                          :defaultValue="
                            project
                              ? project.status
                              : ''
                          "
                        />
                        <TextInput
                          :label="'Progress'"
                          @input="
                            (e) => {
                              projectInfo.summaryReport = e;
                            }
                          "
                          :types="'textarea'"
                          :not="true"
                          :rows="14"
                          :defaultValue="project && project.summaryReport"
                        />
                      </div>
                    </div>
                    <Button
                      :first-l="'Cancel'"
                      :last-l="'Save'"
                      @cancel="$emit('close')"
                      @action="doSave"
                      :hasCancel="true"
                    />
                  </template>
                </Form>
              </div>
              <div v-else>
                <div class="">
                  <div class="w-full text-left sectiony">
                    <div class="heading">Sector</div>
                    <div class="description capitalize">
                      {{ project && project.sector.name }}
                    </div>
                  </div>
                  <div class="w-full text-left sectiony">
                    <div class="heading">Action taken</div>
                    <div class="description">
                      {{ project && project.priorityProject }}
                    </div>
                  </div>
                  <div class="w-full text-left sectiony">
                    <div class="heading">Target</div>
                    <div class="description">
                      {{ project.target }}
                    </div>
                  </div>
                  <div class="w-full text-left sectiony">
                    <div class="heading">Institutions</div>
                    <div class="description">
                      {{ project.institutions.map((x) => x.name).join(",") }}
                    </div>
                  </div>
                  <div class="w-full text-left sectiony">
                    <div class="heading">Meeting Date</div>
                    <div class="description">
                      {{ project && project.meetingDate }}
                    </div>
                  </div>
                  <div class="w-full text-left sectiony">
                    <div class="heading">Timeline</div>
                    <div class="description">
                      {{ project && project.timeline }}
                    </div>
                  </div>
                  <div class="w-full text-left sectiony">
                    <div class="heading">Progress</div>
                    <div class="description">
                      {{ project && project.summaryReport }}
                    </div>
                  </div>
                  <div
                    v-if="project && project.status == 'COMPLETED'"
                    class="w-full text-left sectiony"
                  >
                    <div class="heading">Completed At</div>
                    <div class="description">
                      {{ project && project.completedAt }}
                    </div>
                  </div>
                  <div
                    v-if="project && project.user.filename"
                    class="w-full text-left sectiony"
                  >
                    <div class="heading">Attachment</div>
                    <div
                      class="button flex justify-center place-items-center outlined"
                      @click="
                        download(
                          `http://minicaaf.primature.gov.rw/tmsbackend/api/v1/appointee/load-file/cv/${
                            project && project.user.id
                          }`
                        )
                      "
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 2.604L21 7.604V21.612C20.9997 21.8752 20.895 22.1275 20.7088 22.3135C20.5226 22.4995 20.2702 22.604 20.007 22.604H3.993C3.73038 22.6022 3.47902 22.4971 3.29322 22.3115C3.10742 22.1259 3.00209 21.8746 3 21.612V3.596C3 3.048 3.445 2.604 3.993 2.604H16ZM12 16.604C13.0609 16.604 14.0783 16.1826 14.8284 15.4324C15.5786 14.6823 16 13.6649 16 12.604C16 11.5431 15.5786 10.5257 14.8284 9.77558C14.0783 9.02543 13.0609 8.604 12 8.604H8V16.604H12ZM10 10.604H12C12.5304 10.604 13.0391 10.8147 13.4142 11.1898C13.7893 11.5649 14 12.0736 14 12.604C14 13.1344 13.7893 13.6431 13.4142 14.0182C13.0391 14.3933 12.5304 14.604 12 14.604H10V10.604Z"
                          fill="#28A4E2"
                        />
                      </svg>

                      Download
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateOrUpdateSector
      v-if="selectedSector"
      class="small-nav"
      @close="selectedSector = null"
      @changeSector="changeSector"
      :sector="selectedSector"
      :clusters="clusters"
    />
  </div>
</template>

<script>
import Avatar from "vue-avatar-component";
import { component } from "vue-fullscreen";
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import DragAndDrop from "/src/components/form/DragAndDrop.vue";
import TimeLineItem from "/src/components/Timeline-item.vue";
import TimeCommentItem from "/src/components/TimelineCommentItem.vue";
import NewTimeCommentItem from "/src/components/NewTimelineCommentItem.vue";
import CreateOrUpdateSector from "/src/components/Listings/CreateOrUpdateSector.vue";
import Api from "/src/services/Apis";
import Multiselect from "vue-multiselect";

export default {
  name: "ProjectDetails",
  props: {
    defaultSector: {
      type: String,
      default: "",
    },
    project: Object,
    canAnalystsCompleteProjects: {
      type: Boolean,
      default: false,
    },
    sectors: Array,
    clusters: Array,
    institutions: Array,
  },
  components: {
    Avatar,
    VueFullscreen: component,
    Button,
    TextInput,
    Form,
    DragAndDrop,
    TimeLineItem,
    TimeCommentItem,
    NewTimeCommentItem,
    CreateOrUpdateSector,
    Multiselect,
  },
  data: () => ({
    activeTab: 0,
    activeTab1: 0,
    fullscreen: false,
    selected_timeline: null,
    projectInfo: {
      sector: "",
      priorityProject: "",
      target: "",
      meetingDate: "",
      timeline: "",
      summaryReport: "",
      status: "SUBMITTED",
      institutions: [],
    },
    file: null,
    selectedSector: null,
    selectedCluster: null,
  }),
  methods: {
    changeSector(obj) {
      const { name, clusterId } = obj;

      Api.create(`projects/sectors`, {
        name,
        clusterId,
      })
        .then((response) => {
          if (!response.data.success) {
            this.$store.dispatch("notification/SET_NOTIFICATION", {
              msg: response.data.message,
              hasError: true,
              uptime: 3000,
            });
            return;
          }
          this.sectors = [
            {
              id: response.data.data.id,
              name: response.data.data.name,
              cluster: response.data.data.cluster,
            },
            ...this.sectors,
          ];
          this.$refs.selectSector.value = response.data.data.id;
          this.projectInfo.sector = response.data.data.id;
          this.selectedSector = null;
        })
        .catch((error) => {
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: error.response.data.message,
            hasError: true,
            uptime: 3000,
          });
        });
    },
    toggle() {
      this.$refs.fullscreen.toggle();
    },
    download(url) {
      location.href = url;
    },
    setFile(value) {
      this.file = value;
    },
    saveCommentOrStatus() {
      if (
        this.projectInfo.status === this.project.status &&
        (!this.projectInfo.comment || this.projectInfo.comment === "")
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          hasError: true,
          uptime: 3000,
          msg: "Comment is required",
          // title: "Since you didn't change the status",
        });
        return;
      } else {
        this.$emit("save", {
          project:
            this.projectInfo.status !== this.project.status
              ? { ...this.projectInfo }
              : null,
          comment:
            this.projectInfo.comment && this.projectInfo.comment !== ""
              ? {
                  comment: this.projectInfo.comment,
                  projectTimelineId: this.project.projectTimelines[0].id,
                }
              : null,
        });
      }
    },
    doSave() {
      if (!this.selectedCluster) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          hasError: true,
          uptime: 3000,
          msg: "Cluster is required",
        });
        return;
      }
      if (!this.projectInfo.sector || this.projectInfo.sector.trim() == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          hasError: true,
          uptime: 3000,
          msg: "Sector is required",
        });
        return;
      }

      if (!this.projectInfo.meetingDate || this.projectInfo.meetingDate == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          hasError: true,
          uptime: 3000,
          msg: "MeetingDate is required",
        });
        return;
      }

      if (!this.projectInfo.timeline || this.projectInfo.timeline == "") {
        this.projectInfo.timeline = new Date("2002-12-17T12:00:00");
      }

      if (this.countWords(this.projectInfo.target) > 50) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          hasError: true,
          uptime: 3000,
          msg: "Target should not exceed 50 words",
        });
        return;
      }

      if (
        this.projectInfo.summaryReport &&
        this.projectInfo.summaryReport != ""
      ) {
        if (this.countWords(this.projectInfo.summaryReport) > 100) {
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            hasError: true,
            uptime: 3000,
            msg: "Progress should not exceed 100 words",
          });
          return;
        }
      }

      this.$emit("save", {
        project: {
          ...this.projectInfo,
          institutions: undefined,
          institutionIds: this.projectInfo.institutions.map((e) => e.id),
          sector: this.projectInfo.sector.trim(),
          type: this.$route.path.includes("pcas")
            ? "PCAS"
            : this.$route.path.includes("priority-projects")
            ? "PRIORITY_PROJECTS"
            : this.$route.path.includes("presidential-pledges")
            ? "PRESIDENTIAL_PLEDGES"
            : this.$route.path.includes("pmo-meeting")
            ? "STOCK_TAKING_MEETING"
            : "CABINET_PAPER",
        },
        file: this.file,
      });
    },
    countWords(str) {
      // Remove extra spaces at the beginning and end of the string
      const trimmedString = str.trim();

      // If the string is empty, return 0
      if (trimmedString === "") {
        return 0;
      }

      // Split the string by spaces and count the number of elements
      const words = trimmedString.split(/\s+/);
      return words.length;
    },
  },
  created() {
    if (this.project) {
      if (this.project.timeline == "2002-12-17") this.project.timeline = null;

      this.projectInfo = {
        sector: this.project.sector.id,
        priorityProject: this.project.priorityProject,
        timeline: this.project.timeline,
        summaryReport: this.project.summaryReport,
        status: this.project.status,
        target: this.project.target,
        meetingDate: this.project.meetingDate,
        institutions: this.project.institutions,
        type: this.project.type,
      };
      this.selectedCluster = this.project.sector.cluster.id;
      if (this.$route.query["project-timeline"]) {
        this.activeTab = 2;
        setTimeout(() => {
          const el = document.getElementById(
            `timeline-${this.$route.query["project-timeline"]}`
          );
          el.click();
          this.activeTab1 = 1;

          setTimeout(() => {
            const comment = document.getElementById(
              `timeline-comment-${this.$route.query["project-timeline-comment"]}`
            );
            comment.scrollIntoView({ block: "end", behavior: "smooth" });
            this.$router.push(`/projects`);
          }, 500);
        }, 500);
      }
    } else if (this.defaultSector) {
      this.projectInfo.sector = this.defaultSector;
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import "../assets/scss/imports/colors.scss";
#appointmentDetails {
  // padding: 0 48px;
  .title {
    margin-top: 26px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    text-align: center;

    color: #b6c0c9;
    margin-bottom: 30px;
  }
  .tabs {
    display: flex;
    margin: 0 auto 30px;
    width: fit-content;
    .tab {
      padding: 15px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      color: #b7bec9;
      text-align: center;
      border-bottom: 2px solid #eff2f3;
      &.active {
        color: $primary;
        font-weight: 600;
        border-bottom-color: $primary;
      }
    }
  }
  .details {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height */

    color: #b6c0c9;
    .d-flex {
      margin-bottom: 30px;
      svg {
        margin-right: 15px;
        fill: #b6c0c9;
      }
      &:hover {
        color: $primary;
        svg {
          fill: $primary;
        }
      }
    }
  }
  .appointment-details {
    .d-flex {
      margin-right: 53px;
    }
    .last {
      margin-bottom: 139px;
    }
  }
  .user-details {
    .last {
      margin-bottom: 74px;
    }
    .image {
      img {
        max-width: 125px;
        width: 125px;
        height: 130px;
        border-radius: 204px;
        &.full {
          max-width: 100%;
          width: 70vh;
          height: 70vh;
          border-radius: 5%;
          margin-top: 50px;
        }
      }
      .text {
        margin-top: 15px;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;

        color: #032b3d;
      }
    }
  }
  .sectiony {
    margin-bottom: 30px;
    .heading {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 10px;
      color: #bcbdbe;
      text-align: left;
    }
    .description {
      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;

      color: #032b3d;
      max-width: 100%;
      overflow-wrap: anywhere;
    }
  }
  .actions {
    bottom: 78px;
  }
  .button {
    width: 326px;
    height: 50px;
    border-radius: 4px;
    background: #b6c0c9;
    cursor: pointer;
    margin-right: 60px;
    &.important {
      background: $primary;
    }
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;

    color: #ffffff;
    &.outlined {
      border: 1px solid #28a4e2;
      background: white;
      color: $primary;
      width: 182px;
      height: 41px;
      text-decoration: underline;
    }
  }
  .margin_bottom {
    margin-bottom: 30px;
  }
}
</style>
