var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.auth.user.roles[0].name)?_c('div',{staticClass:"mx-8",attrs:{"id":"project-listing"}},[_c('div',{staticClass:"justify-between"},[_c('div',[_c('h1',{staticClass:"appointments text-justify text-xl mt-12 text-appointments"},[_vm._v(" "+_vm._s(_vm.$route.path.includes("pcas") ? "PCAs" : _vm.$route.path.includes("priority-projects") ? "Priority Projects" : _vm.$route.path.includes("presidential-pledges") ? "Presidential Pledges" : _vm.$route.path.includes("pmo-meeting") ? "PMO Meetings / StockTakes" : _vm.$route.path.includes("papers") ? "Cabinet Papers" : "Closed Files")+" ")]),_c('div',{staticClass:"md:flex"},[(
            !_vm.$route.path.includes('closed') &&
            _vm.$store.state.auth.user.roles[0].name !== 'ADMIN' &&
            _vm.$store.state.auth.user.projectRoles
              ? _vm.$store.state.auth.user.projectRoles.status !== 'VIEWER'
              : false
          )?_c('Button',{attrs:{"route":"","title":("Add " + (_vm.$route.path.includes('pcas')
              ? 'PCA'
              : _vm.$route.path.includes('priority-projects')
              ? 'Priority Project'
              : _vm.$route.path.includes('presidential-pledges')
              ? 'Presidential Pledge'
              : _vm.$route.path.includes('pmo-meeting')
              ? 'PMO Meeting'
              : 'Cabinet Paper')),"icon":"add","isSecretary":true},on:{"click":function($event){_vm.defaultSector = '';
            _vm.showModal = true;}}}):_vm._e(),_c('Button',{staticClass:"md:ml-auto",attrs:{"route":"","title":"Export projects","icon":"xlsx","isSecretary":true},on:{"click":function($event){_vm.showExportModal = true}}})],1)]),_c('div',{staticClass:"sm:flex mt-12 mx-auto block md;flex justify-center"},[_c('Select',{staticClass:"mb-4",attrs:{"values":_vm.clusters.map(function (e) {
            return { name: e.name, id: e.id };
          }),"label":"Cluster","name":"cluster"},on:{"selected":function (value) {
            _vm.selectedCluster = value;
          }}}),_c('Select',{staticClass:"mb-4",attrs:{"values":_vm.sectors
            .filter(
              function (x) { return _vm.selectedCluster == null ||
                _vm.selectedCluster == 'ALL' ||
                x.cluster.id == _vm.selectedCluster; }
            )
            .map(function (e) {
              return { name: e.name, id: e.id };
            }),"label":"Sector","name":"sector"},on:{"selected":function (value) {
            _vm.selectedSector = value;
          }}}),_c('Select',{staticClass:"mb-4",attrs:{"values":_vm.years.map(function (e) {
            return { name: e.year, id: e.year };
          }),"defaultValue":_vm.selectedYear,"label":"Year","name":"year"},on:{"selected":function (value) {
            _vm.selectedYear = value;
          }}}),(_vm.$route.path.includes('closed'))?_c('Select',{staticClass:"mb-4",attrs:{"defaultValue":_vm.selectedType,"values":_vm.projectTypes.filter(function (x) { return x.id != 'ALL'; }),"label":"Category","name":"TYPE"},on:{"selected":function (value) {
            _vm.selectedType = value;
          }}}):(
          _vm.$route.path.includes('papers') &&
          _vm.$store.state.auth.user.projectRoles
            ? _vm.$store.state.auth.user.projectRoles.status !== 'VIEWER'
            : false
        )?_c('Select',{staticClass:"mb-4",attrs:{"defaultValue":'ALL',"values":[
          { name: 'SUBMITTED', id: 'SUBMITTED' },
          { name: 'SENT_BACK', id: 'SENT_BACK' },
          { name: 'UNDER_ANALYSIS', id: 'UNDER_ANALYSIS' },
          { name: 'APPROVED', id: 'APPROVED' } ],"label":"Status","name":"STATUS"},on:{"selected":function (value) {
            _vm.selectedStatus = value;
          }}}):_vm._e()],1)]),(_vm.elementsAvailble)?_c('div',[_c('table',{staticClass:"table-auto border-collapse w-full border border-slate-500 mb-4"},[_c('thead',[_c('tr',{staticClass:"flex flex-col flex-no wrap table-row rounded-l-lg rounded-none mb-2 mb-0"},[(_vm.fields['project_priority'])?_c('th',{staticClass:"p-3 text-left border border-slate-600"},[_c('span',{staticClass:"flex sm:flex text-table text-xs text-left"},[_vm._v("Action taken")])]):_vm._e(),(_vm.fields['target'])?_c('th',{staticClass:"p-3 text-left border border-slate-600"},[_c('span',{staticClass:"flex text-table text-xs"},[_vm._v("Target")])]):_vm._e(),(_vm.fields['timeline'])?_c('th',{staticClass:"p-3 text-left border border-slate-600"},[_c('span',{staticClass:"flex sm:flex text-table text-xs text-left"},[_vm._v("Timeline")])]):_vm._e(),(_vm.fields['progress'])?_c('th',{staticClass:"p-3 text-left border border-slate-600"},[_c('span',{staticClass:"flex text-table text-xs"},[_vm._v("Progress")])]):_vm._e()])]),_c('tbody',{staticClass:"sm:flex-1 sm:flex-none"},_vm._l((_vm.filteredProjects),function(project){return _c('tr',{key:project.id,staticClass:"sm:flex sm:flex-col sm:flex-no sm:wrap sm:table-row sm:mb-2 sm:mb-0 main-header sm:header"},[(_vm.fields['project_priority'])?_c('td',{staticClass:"w-1/3 p-3 text-left description border border-slate-700",attrs:{"title":project.priorityProject}},[_c('div',{staticClass:"flex place-items-center"},[(project.file)?_c('img',{staticClass:"mt-1.5 mr-2 w-6 cursor-pointer",attrs:{"src":("/icons/" + (project.file.name.includes('zip')
                    ? 'zip.svg'
                    : project.file.name.includes('doc') ||
                      project.file.name.includes('docx')
                    ? 'docx.png'
                    : project.file.name.includes('pdf')
                    ? 'pdf.png'
                    : project.file.name.includes('xlsx')
                    ? 'xlsx.png'
                    : project.file.name.includes('pptx') ||
                      project.file.name.includes('ppt')
                    ? 'pptx.png'
                    : '')),"alt":""},on:{"click":function($event){return _vm.download(project.file.url)}}}):_vm._e(),_c('p',{class:("text-xs " + (project.status == 'COMPLETED'
                    ? 'sm:text-secondary'
                    : 'sm:text-values') + " title mt-2 sm:mt-0 pl-2 sm:pl-0")},[_vm._v(" "+_vm._s(_vm.trimWords( project.priorityProject, 15, _vm.countWords(project.priorityProject) > 15 ))+" ")])])]):_vm._e(),(_vm.fields['target'])?_c('td',{staticClass:"border border-slate-700",attrs:{"title":project.target}},[_c('div',{staticClass:"w-full sm:h-4 sm:p-0.5 bg-white sm:text-values sm:text-sm sm:title short"},[_c('span',{staticClass:"sm:text-values sm:text-xs"},[_vm._v(_vm._s(_vm.trimWords(project.target, 15, _vm.countWords(project.target) > 15)))])])]):_vm._e(),(_vm.fields['timeline'])?_c('td',{class:("text-center border border-slate-700 " + (_vm.getStatus(
              project
            ))),attrs:{"title":(function (stat) {
                return stat == 'ON_TRACK'
                  ? 'On Track'
                  : stat == 'ON_WATCH'
                  ? 'On Watch'
                  : stat == 'OFF_TRACK'
                  ? 'Off Track'
                  : '';
              })(_vm.getStatus(project))}},[_c('span',{staticClass:"ml-7 sm:ml-0 sm:text-sm sm:title"},[_vm._v(_vm._s(project.timeline == "2002-12-17" ? "Not set" : project.timeline))])]):_vm._e(),(_vm.fields['progress'])?_c('td',{staticClass:"px-3 py-2 border border-slate-700",attrs:{"title":project.summaryReport}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-full sm:h-4 sm:p-0.5 bg-white sm:text-values sm:text-sm sm:title short"},[_c('span',{staticClass:"sm:text-values sm:text-xs"},[_vm._v(_vm._s(_vm.trimWords( project.summaryReport, 40, _vm.countWords(project.summaryReport) > 40 )))])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-16 rounded text-view h-7 bg-table p-0.5 pt-1 pb-1 cursor-pointer text-center mr-5 my-auto",staticStyle:{"height":"32px"},attrs:{"id":("view-" + (project.id))},on:{"click":function($event){return _vm.viewProject(project)}}},[_c('span',{staticClass:"view"},[_vm._v("Details")])]),(
                    _vm.$store.state.auth.user.roles[0].name == 'ADMIN' ||
                    _vm.$store.state.auth.user.projectRoles.status == 'SUPERVISOR'
                  )?_c('div',{staticClass:"w-16 rounded text-white h-7 bg-delete p-0.5 pt-1 pb-1 cursor-pointer text-center my-auto",staticStyle:{"height":"32px"},attrs:{"id":("delete-" + (project.id))},on:{"click":function($event){_vm.projectToDeleteId = project.id;
                    _vm.showDeleteModal = true;}}},[_c('span',{staticClass:"view"},[_vm._v("Delete")])]):_vm._e()])])]):_vm._e()])}),0)])]):_vm._e(),_c('ElementsNotFound',{directives:[{name:"show",rawName:"v-show",value:(!_vm.elementsAvailble),expression:"!elementsAvailble"}],attrs:{"isLoading":_vm.loading,"type":"Documents"}}),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"small-nav",on:{"close":function($event){_vm.selectedProject = null;
      _vm.showModal = false;}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.showModal)?_c('ProjectDetails',{attrs:{"defaultSector":_vm.defaultSector,"project":_vm.selectedProject,"sectors":_vm.sectors.map(function (e) {
            return { name: e.name, id: e.id, cluster: e.cluster };
          }).concat( [{ name: 'Add new', id: 'new' }] ),"clusters":_vm.clusters,"institutions":_vm.institutions,"canAnalystsCompleteProjects":_vm.canAnalystsCompleteProjects},on:{"save":_vm.saveProject,"close":function($event){_vm.showModal = false;
          _vm.selectedProject = null;}}}):_vm._e()]},proxy:true}],null,false,4078961070)}),_c('DeleteModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDeleteModal),expression:"showDeleteModal"}],staticClass:"small-nav",on:{"deleted":_vm.deleteProject,"close":function($event){_vm.showDeleteModal = false}}}),(_vm.showExportModal)?_c('ExportProjects',{attrs:{"data":{
      year: _vm.selectedYear,
      years: [
        { name: 'ALL', id: 'ALL' } ].concat( _vm.years.map(function (e) {
          return { name: e.year, id: e.year };
        }) ),
      cluster: _vm.selectedCluster.length ? _vm.selectedCluster : 'ALL',
      clusters: [
        { name: 'ALL', id: 'ALL' } ].concat( _vm.clusters.map(function (e) {
          return { name: e.name, id: e.id };
        }) ),
      sector: _vm.selectedSector.length ? _vm.selectedSector : 'ALL',
      sectors: [
        { name: 'ALL', id: 'ALL' } ].concat( _vm.sectors.map(function (e) {
          return { name: e.name, id: e.id };
        }) ),
      status: _vm.selectedStatus ? _vm.selectedStatus : 'ALL',
      statuses: [
        { name: 'ALL', id: 'ALL' },
        { name: 'SUBMITTED', id: 'SUBMITTED' },
        { name: 'COMPLETED', id: 'COMPLETED' } ],
      types: _vm.projectTypes,

      type: _vm.$route.path.includes('pcas')
        ? 'PCAS'
        : _vm.$route.path.includes('priority-projects')
        ? 'PRIORITY_PROJECTS'
        : _vm.$route.path.includes('presidential-pledges')
        ? 'PRESIDENTIAL_PLEDGES'
        : _vm.$route.path.includes('pmo-meeting')
        ? 'STOCK_TAKING_MEETING'
        : 'CABINET_PAPER',
    }},on:{"close":function($event){_vm.showExportModal = false},"export":_vm.exportProjects}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }