<template>
  <div
    id="project-listing"
    class="mx-8"
    v-if="$store.state.auth.user.roles[0].name"
  >
    <div class="justify-between">
      <div>
        <h1 class="appointments text-justify text-xl mt-12 text-appointments">
          {{
            $route.path.includes("pcas")
              ? "PCAs"
              : $route.path.includes("priority-projects")
              ? "Priority Projects"
              : $route.path.includes("presidential-pledges")
              ? "Presidential Pledges"
              : $route.path.includes("pmo-meeting")
              ? "PMO Meetings / StockTakes"
              : $route.path.includes("papers")
              ? "Cabinet Papers"
              : "Closed Files"
          }}
        </h1>
        <div class="md:flex">
          <Button
            @click="
              defaultSector = '';
              showModal = true;
            "
            route=""
            :title="`Add ${
              $route.path.includes('pcas')
                ? 'PCA'
                : $route.path.includes('priority-projects')
                ? 'Priority Project'
                : $route.path.includes('presidential-pledges')
                ? 'Presidential Pledge'
                : $route.path.includes('pmo-meeting')
                ? 'PMO Meeting'
                : 'Cabinet Paper'
            }`"
            icon="add"
            :isSecretary="true"
            v-if="
              !$route.path.includes('closed') &&
              $store.state.auth.user.roles[0].name !== 'ADMIN' &&
              $store.state.auth.user.projectRoles
                ? $store.state.auth.user.projectRoles.status !== 'VIEWER'
                : false
            "
          />
          <Button
            @click="showExportModal = true"
            route=""
            title="Export projects"
            icon="xlsx"
            :isSecretary="true"
            class="md:ml-auto"
          />
        </div>
      </div>
      <div class="sm:flex mt-12 mx-auto block md;flex justify-center">
        <Select
          class="mb-4"
          :values="
            clusters.map((e) => {
              return { name: e.name, id: e.id };
            })
          "
          label="Cluster"
          @selected="
            (value) => {
              selectedCluster = value;
            }
          "
          name="cluster"
        />
        <Select
          class="mb-4"
          :values="
            sectors
              .filter(
                (x) =>
                  selectedCluster == null ||
                  selectedCluster == 'ALL' ||
                  x.cluster.id == selectedCluster
              )
              .map((e) => {
                return { name: e.name, id: e.id };
              })
          "
          label="Sector"
          @selected="
            (value) => {
              selectedSector = value;
            }
          "
          name="sector"
        />
        <Select
          class="mb-4"
          :values="
            years.map((e) => {
              return { name: e.year, id: e.year };
            })
          "
          :defaultValue="selectedYear"
          label="Year"
          @selected="
            (value) => {
              selectedYear = value;
            }
          "
          name="year"
        />
        <Select
          v-if="$route.path.includes('closed')"
          class="mb-4"
          :defaultValue="selectedType"
          :values="projectTypes.filter((x) => x.id != 'ALL')"
          label="Category"
          @selected="
            (value) => {
              selectedType = value;
            }
          "
          name="TYPE"
        />
        <Select
          v-else-if="
            $route.path.includes('papers') &&
            $store.state.auth.user.projectRoles
              ? $store.state.auth.user.projectRoles.status !== 'VIEWER'
              : false
          "
          class="mb-4"
          :defaultValue="'ALL'"
          :values="[
            { name: 'SUBMITTED', id: 'SUBMITTED' },
            { name: 'SENT_BACK', id: 'SENT_BACK' },
            { name: 'UNDER_ANALYSIS', id: 'UNDER_ANALYSIS' },
            { name: 'APPROVED', id: 'APPROVED' },
          ]"
          label="Status"
          @selected="
            (value) => {
              selectedStatus = value;
            }
          "
          name="STATUS"
        />
        <!-- <Search class="mb-4" label="Search by name" @input="updateQuery" /> -->
      </div>
    </div>

    <div v-if="elementsAvailble">
      <table
        class="table-auto border-collapse w-full border border-slate-500 mb-4"
      >
        <thead>
          <tr
            class="flex flex-col flex-no wrap table-row rounded-l-lg rounded-none mb-2 mb-0"
          >
            <!-- <th class="p-3 text-left border border-slate-600">
              <span class="flex text-table text-xs">Sector</span>
            </th> -->
            <th
              v-if="fields['project_priority']"
              class="p-3 text-left border border-slate-600"
            >
              <span class="flex sm:flex text-table text-xs text-left"
                >Action taken</span
              >
            </th>
            <th
              v-if="fields['target']"
              class="p-3 text-left border border-slate-600"
            >
              <span class="flex text-table text-xs">Target</span>
            </th>
            <th
              v-if="fields['timeline']"
              class="p-3 text-left border border-slate-600"
            >
              <span class="flex sm:flex text-table text-xs text-left"
                >Timeline</span
              >
            </th>
            <th
              v-if="fields['progress']"
              class="p-3 text-left border border-slate-600"
            >
              <span class="flex text-table text-xs">Progress</span>
            </th>
            <!-- <th class="p-3 text-left border border-slate-600">
              <span class="flex text-table text-xs">Action</span>
            </th> -->
          </tr>
        </thead>

        <tbody class="sm:flex-1 sm:flex-none">
          <tr
            v-for="project in filteredProjects"
            :key="project.id"
            class="sm:flex sm:flex-col sm:flex-no sm:wrap sm:table-row sm:mb-2 sm:mb-0 main-header sm:header"
          >
            <!-- <td
              :rowspan="project.rowspan"
              v-if="project.rowspan"
              class="p-3 border border-slate-700 cursor-pointer"
              @click="
                defaultSector = project.sector.id;
                showModal = true;
              "
              :title="`Click to add project in ${project.sector.name}`"
            >
              <div class="">
                <p
                  class="md:ml-2 md:mt-2 text-xs sm:text-values title mt-2 sm:mt-0 pl-2 sm:pl-0"
                >
                  {{ project.sector.name }}
                </p>
                <p
                  v-if="project.completed !== 0"
                  class="md:ml-2 md:mt-2 text-xs sm:text-values title mt-2 sm:mt-0 pl-2 sm:pl-0"
                >
                  {{ project.completed }} / {{ project.total }} completed
                </p>
              </div>
            </td> -->

            <td
              v-if="fields['project_priority']"
              class="w-1/3 p-3 text-left description border border-slate-700"
              :title="project.priorityProject"
            >
              <div class="flex place-items-center">
                <img
                  v-if="project.file"
                  :src="`/icons/${
                    project.file.name.includes('zip')
                      ? 'zip.svg'
                      : project.file.name.includes('doc') ||
                        project.file.name.includes('docx')
                      ? 'docx.png'
                      : project.file.name.includes('pdf')
                      ? 'pdf.png'
                      : project.file.name.includes('xlsx')
                      ? 'xlsx.png'
                      : project.file.name.includes('pptx') ||
                        project.file.name.includes('ppt')
                      ? 'pptx.png'
                      : ''
                  }`"
                  @click="download(project.file.url)"
                  alt=""
                  class="mt-1.5 mr-2 w-6 cursor-pointer"
                />
                <p
                  :class="`text-xs ${
                    project.status == 'COMPLETED'
                      ? 'sm:text-secondary'
                      : 'sm:text-values'
                  } title mt-2 sm:mt-0 pl-2 sm:pl-0`"
                >
                  {{
                    trimWords(
                      project.priorityProject,
                      15,
                      countWords(project.priorityProject) > 15
                    )
                  }}
                </p>
              </div>
            </td>
            <td
              v-if="fields['target']"
              class="border border-slate-700"
              :title="project.target"
            >
              <div
                class="w-full sm:h-4 sm:p-0.5 bg-white sm:text-values sm:text-sm sm:title short"
              >
                <span class="sm:text-values sm:text-xs">{{
                  trimWords(project.target, 15, countWords(project.target) > 15)
                }}</span>
              </div>
            </td>
            <td
              v-if="fields['timeline']"
              :class="`text-center border border-slate-700 ${getStatus(
                project
              )}`"
              :title="
                ((stat) => {
                  return stat == 'ON_TRACK'
                    ? 'On Track'
                    : stat == 'ON_WATCH'
                    ? 'On Watch'
                    : stat == 'OFF_TRACK'
                    ? 'Off Track'
                    : '';
                })(getStatus(project))
              "
            >
              <span class="ml-7 sm:ml-0 sm:text-sm sm:title">{{
                project.timeline == "2002-12-17" ? "Not set" : project.timeline
              }}</span>
            </td>
            <td
              v-if="fields['progress']"
              class="px-3 py-2 border border-slate-700"
              :title="project.summaryReport"
            >
              <div class="flex">
                <div
                  class="w-full sm:h-4 sm:p-0.5 bg-white sm:text-values sm:text-sm sm:title short"
                >
                  <span class="sm:text-values sm:text-xs">{{
                    trimWords(
                      project.summaryReport,
                      40,
                      countWords(project.summaryReport) > 40
                    )
                  }}</span>
                </div>
                <div class="flex">
                  <div
                    :id="`view-${project.id}`"
                    class="w-16 rounded text-view h-7 bg-table p-0.5 pt-1 pb-1 cursor-pointer text-center mr-5 my-auto"
                    @click="viewProject(project)"
                    style="height: 32px"
                  >
                    <span class="view">Details</span>
                  </div>

                  <div
                    :id="`delete-${project.id}`"
                    v-if="
                      $store.state.auth.user.roles[0].name == 'ADMIN' ||
                      $store.state.auth.user.projectRoles.status == 'SUPERVISOR'
                    "
                    class="w-16 rounded text-white h-7 bg-delete p-0.5 pt-1 pb-1 cursor-pointer text-center my-auto"
                    @click="
                      projectToDeleteId = project.id;
                      showDeleteModal = true;
                    "
                    style="height: 32px"
                  >
                    <span class="view">Delete</span>
                  </div>
                </div>
              </div>
            </td>

            <!-- <td class="text-values text-sm p-3 border border-slate-700"> -->
            <!-- <div
                :id="`view-${project.id}`"
                class="w-16 rounded text-view h-7 bg-table p-0.5 pt-1 pb-1 cursor-pointer text-center mb-4"
                @click="viewProject(project)"
              >
                <span class="view">View</span>
              </div>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <ElementsNotFound
      v-show="!elementsAvailble"
      :isLoading="loading"
      type="Documents"
    />
    <Modal
      class="small-nav"
      v-show="showModal"
      @close="
        selectedProject = null;
        showModal = false;
      "
    >
      <template v-slot:content>
        <ProjectDetails
          v-if="showModal"
          :defaultSector="defaultSector"
          :project="selectedProject"
          :sectors="[
            ...sectors.map((e) => {
              return { name: e.name, id: e.id, cluster: e.cluster };
            }),
            { name: 'Add new', id: 'new' },
          ]"
          :clusters="clusters"
          :institutions="institutions"
          @save="saveProject"
          @close="
            showModal = false;
            selectedProject = null;
          "
          :canAnalystsCompleteProjects="canAnalystsCompleteProjects"
        />
      </template>
    </Modal>
    <DeleteModal
      class="small-nav"
      @deleted="deleteProject"
      v-show="showDeleteModal"
      @close="showDeleteModal = false"
    />
    <ExportProjects
      v-if="showExportModal"
      :data="{
        year: selectedYear,
        years: [
          { name: 'ALL', id: 'ALL' },
          ...years.map((e) => {
            return { name: e.year, id: e.year };
          }),
        ],
        cluster: selectedCluster.length ? selectedCluster : 'ALL',
        clusters: [
          { name: 'ALL', id: 'ALL' },
          ...clusters.map((e) => {
            return { name: e.name, id: e.id };
          }),
        ],
        sector: selectedSector.length ? selectedSector : 'ALL',
        sectors: [
          { name: 'ALL', id: 'ALL' },
          ...sectors.map((e) => {
            return { name: e.name, id: e.id };
          }),
        ],
        status: selectedStatus ? selectedStatus : 'ALL',
        statuses: [
          { name: 'ALL', id: 'ALL' },
          { name: 'SUBMITTED', id: 'SUBMITTED' },
          { name: 'COMPLETED', id: 'COMPLETED' },
        ],
        types: projectTypes,

        type: $route.path.includes('pcas')
          ? 'PCAS'
          : $route.path.includes('priority-projects')
          ? 'PRIORITY_PROJECTS'
          : $route.path.includes('presidential-pledges')
          ? 'PRESIDENTIAL_PLEDGES'
          : $route.path.includes('pmo-meeting')
          ? 'STOCK_TAKING_MEETING'
          : 'CABINET_PAPER',
      }"
      @close="showExportModal = false"
      @export="exportProjects"
    />
  </div>
</template>
<script>
import Button from "/src/components/Listings/Button.vue";
import DocumentActionButtons from "/src/components/Listings/DocumentActionButtons.vue";
import Search from "/src/components/searchInput.vue";
import Select from "/src/components/SelectInput.vue";
import Api from "/src/services/Apis";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import { trimString, trimWords } from "/src/functions";
import ProjectDetails from "/src/components/ProjectDetails.vue";
import Modal from "/src/components/Modal.vue";
import ExportProjects from "/src/components/Listings/ExportProjects.vue";
import { mapActions } from "vuex";
import DeleteModal from "/src/components/Listings/DeleteModal.vue";

export default {
  name: "DocumentDetails",
  components: {
    Button,
    DocumentActionButtons,
    Search,
    Select,
    ElementsNotFound,
    ProjectDetails,
    Modal,
    ExportProjects,
    DeleteModal,
  },

  data() {
    return {
      elementsAvailble: true,
      loading: true,
      showModal: false,
      query: "",
      years: [],
      selectedYear: "ALL",
      selectedSector: "",
      sectors: [],
      clusters: [],
      projects: [],
      institutions: [],
      fields: [],
      selectedProject: null,
      selectedStatus: "",
      selectedType: "ALL",
      defaultSector: "",
      canAnalystsCompleteProjects: false,
      showExportModal: false,
      selectedCluster: "",
      projectToDeleteId: "",
      showDeleteModal: false,
      projectTypes: [
        { name: "ALL", id: "ALL" },
        { name: "PCAS", id: "PCAS" },
        { name: "Cabinet Papers", id: "CABINET_PAPER" },
        { name: "Priority Projects", id: "PRIORITY_PROJECTS" },
        { name: "Presidential Pledges", id: "PRESIDENTIAL_PLEDGES" },
        { name: "PMO Meetings / StockTakes", id: "STOCK_TAKING_MEETING" },
      ],
    };
  },
  methods: {
    ...mapActions({ getFields: "settings/getAllowedFields" }),
    preselectProject(id) {
      const el = document.getElementById(`view-${id}`);
      if (el) {
        el.click();
      }

      // if (!this.$route.query["project-timeline"]) {
      //   this.$router.push(`/projects`);
      // }
    },
    exportProjects(data) {
      Api.get(
        `projects/export?cluster=${data.cluster}&sector=${data.sector}&year=${data.year}&status=${data.status}&type=${data.type}`,
        {
          responseType: "blob",
        }
      ).then((res) => {
        const filename = "exported-projects.xlsx";
        let data = new Blob([res.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(data);
        link.download = filename.replace(/"/g, "");
        link.click();
        this.showExportModal = false;
      });
    },
    updateQuery(value) {
      this.query = value;
      this.searchDocument();
    },
    getStatus(project) {
      if (project.timeline == "2002-12-17") {
        return "ON_TRACK";
      }

      let totalHours =
        (new Date(project.timeline).getTime() -
          new Date(project.createdAt).getTime()) /
        (1000 * 3600);
      let spentHours =
        ((project.status == "COMPLETED"
          ? new Date(project.completedAt).getTime()
          : new Date().getTime()) -
          new Date(project.createdAt).getTime()) /
        (1000 * 3600);
      let completion = (spentHours / totalHours) * 100;

      let status;

      if (completion <= 85) {
        status = "ON_TRACK";
      } else if (completion > 85 && completion <= 115) {
        status = "ON_WATCH";
      } else {
        status = "OFF_TRACK";
      }
      return status;
      // } else {
      //   return project.status;
      // }
    },
    viewProject(project) {
      this.selectedProject = project;
      this.showModal = true;
    },
    trimString,
    trimWords,
    async getProjects() {
      const yearsRes = await Api.get("projects/list-years");
      this.years = yearsRes.data;
      // this.selectedYear = this.years[0]
      //   ? this.years[0].year
      //   : new Date().getFullYear();
      const clustersRes = await Api.get("clusters");
      this.clusters = clustersRes.data.content;
      const sectorsRes = await Api.get("projects/sectors");
      this.sectors = sectorsRes.data.data;
      const institutionsRes = await Api.get("institutions");
      this.institutions = institutionsRes.data.content;
      const projectsRes = await Api.get("projects/");
      this.projects = projectsRes.data.data;
      Api.get("projects/can-analysts-complete-project").then((response) => {
        this.canAnalystsCompleteProjects = response.data.data;
      });
    },
    download(url) {
      location.href = url;
    },
    saveProject(data) {
      const { project, file, comment } = data;
      let yearFound = false;
      let sectorFound = false;
      if (!this.selectedProject)
        Api.create("projects", project)
          .then((response) => {
            const hasError = response.data.data ? false : true;
            const msg = response.data.data
              ? "Project created sucessfully"
              : response.data.message;
            if (response.data.data) {
              for (let i in this.projects) {
                if (this.projects[i].year == new Date().getFullYear()) {
                  yearFound = true;
                  for (const sector of this.projects[i].sectors) {
                    if (sector.sector == response.data.data.sector.name) {
                      sectorFound = true;
                      sector.projects.unshift(response.data.data);
                      break;
                    }
                  }
                  break;
                }
              }

              if (file) {
                const formData = new FormData();
                formData.append("file", file);
                Api.update(
                  "projects",
                  `${response.data.data.id}/upload-file`,
                  formData
                ).then((response) => {
                  const hasError = response.data.data ? false : true;
                  const msg = response.data.data
                    ? "File uploaded sucessfully"
                    : response.data.message;
                  if (response.data.data) {
                    for (let i in this.projects) {
                      if (this.projects[i].year == response.data.data.year) {
                        for (const sector of this.projects[i].sectors) {
                          if (sector.sector == response.data.data.sector) {
                            for (const project of sector.projects) {
                              if (project.id == response.data.data.id) {
                                project.file = response.data.data.file;
                                break;
                              }
                            }
                            break;
                          }
                        }
                        break;
                      }
                    }
                    if (!sectorFound || !yearFound) {
                      this.getProjects();
                    }
                  }
                  this.$store.dispatch("notification/SET_NOTIFICATION", {
                    msg,
                    hasError,
                    uptime: 3000,
                  });
                });
              } else {
                if (!sectorFound || !yearFound) {
                  this.getProjects();
                }
              }

              this.showModal = false;
              this.selectedProject = null;
            }
            this.$store.dispatch("notification/SET_NOTIFICATION", {
              msg,
              hasError,
              uptime: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      else {
        if (project) {
          const projectId = this.selectedProject.id;
          if (
            project.sector !== this.selectedProject.sector ||
            project.priorityProject !== this.selectedProject.priorityProject ||
            project.status !== this.selectedProject.status ||
            project.timeline !== this.selectedProject.timeline
          )
            Api.update("projects", this.selectedProject.id, project)
              .then((response) => {
                const hasError = response.data.data ? false : true;
                const msg = response.data.data
                  ? "Project updated sucessfully"
                  : response.data.message;
                if (response.data.data) {
                  for (let i in this.projects) {
                    if (this.projects[i].year == response.data.data.year) {
                      yearFound = true;
                      for (const sector of this.projects[i].sectors) {
                        if (
                          this.selectedProject.sector.id !==
                          response.data.data.sector.id
                        ) {
                          if (
                            sector.sector == this.selectedProject.sector.name
                          ) {
                            sectorFound = true;
                            sector.projects = sector.projects.filter(
                              (project) =>
                                project.id !== this.selectedProject.id
                            );
                          }
                          if (sector.sector == response.data.data.sector.name) {
                            sector.projects.unshift(response.data.data);
                          }
                        }
                        if (sector.sector == response.data.data.sector.name) {
                          sector.projects = sector.projects.map((project) => {
                            if (project.id == response.data.data.id) {
                              return response.data.data;
                            }
                            return project;
                          });
                          break;
                        }
                      }
                      break;
                    }
                  }
                  this.showModal = false;
                  this.selectedProject = null;
                }
                this.$store.dispatch("notification/SET_NOTIFICATION", {
                  msg,
                  hasError,
                  uptime: 3000,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          if (file) {
            const formData = new FormData();
            formData.append("file", file);
            Api.update("projects", `${projectId}/upload-file`, formData).then(
              (response) => {
                const hasError = response.data.data ? false : true;
                const msg = response.data.data
                  ? "File uploaded sucessfully"
                  : response.data.message;
                if (response.data.data) {
                  for (let i in this.projects) {
                    if (this.projects[i].year == response.data.data.year) {
                      for (const sector of this.projects[i].sectors) {
                        if (sector.sector == response.data.data.sector.name) {
                          for (const project of sector.projects) {
                            if (project.id == response.data.data.id) {
                              project.file = response.data.data.file;
                              break;
                            }
                          }
                          break;
                        }
                      }
                      break;
                    }
                  }
                  if (!sectorFound || !yearFound) {
                    this.getProjects();
                  }
                  this.showModal = false;
                  this.selectedProject = null;
                }
                this.$store.dispatch("notification/SET_NOTIFICATION", {
                  msg,
                  hasError,
                  uptime: 3000,
                });
              }
            );
          }
        } else {
          if (!sectorFound || !yearFound) {
            this.getProjects();
          }
        }
        if (comment) {
          Api.create("project-timeline-comments", comment)
            .then((response) => {
              const hasError = response.data.data ? false : true;
              const msg = response.data.data
                ? "Comment created sucessfully"
                : response.data.message;
              if (response.data.data) {
                // here
                for (let i in this.projects) {
                  if (this.projects[i].year == response.data.data.year) {
                    this.projects[i].projects = this.projects[i].projects.map(
                      (project) => {
                        if (project.id === response.data.data.project_id) {
                          project.comments.unshift(response.data.data);
                        }
                        return project;
                      }
                    );
                    break;
                  }
                  // console.log(response.data.data, file);
                  // handle file upload
                }
                this.showModal = false;
                this.selectedProject = null;
              }
              this.$store.dispatch("notification/SET_NOTIFICATION", {
                msg,
                hasError,
                uptime: 3000,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    countWords(str) {
      // Remove extra spaces at the beginning and end of the string
      const trimmedString = str.trim();

      // If the string is empty, return 0
      if (trimmedString === "") {
        return 0;
      }

      // Split the string by spaces and count the number of elements
      const words = trimmedString.split(/\s+/);
      return words.length;
    },
    async deleteProject() {
      Api.update("projects", this.projectToDeleteId + "/DELETED").then(
        (response) => {
          const hasError = response.data.data ? false : true;
          const msg = response.data.data
            ? "Project deleted sucessfully"
            : response.data.message;
          if (response.data.data) {
            for (let i in this.projects) {
              if (this.projects[i].year == response.data.data.year) {
                for (const sector of this.projects[i].sectors) {
                  if (sector.sector == response.data.data.sector.name) {
                    sector.projects = sector.projects.filter(
                      (project) => project.id !== response.data.data.id
                    );
                  }
                }
                break;
              }
            }
            this.showDeleteModal = false;
            this.projectToDeleteId = "";
          }

          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg,
            hasError,
            uptime: 3000,
          });
        }
      );
    },
  },

  watch: {
    async $route() {
      if (this.$route.query.project) {
        await this.getProjects();
        this.preselectProject(this.$route.query.project);
      }
    },
  },

  computed: {
    filteredProjects() {
      let res = [];
      const type = this.$route.path.includes("pcas")
        ? "PCAS"
        : this.$route.path.includes("priority-projects")
        ? "PRIORITY_PROJECTS"
        : this.$route.path.includes("presidential-pledges")
        ? "PRESIDENTIAL_PLEDGES"
        : this.$route.path.includes("pmo-meeting")
        ? "STOCK_TAKING_MEETING"
        : this.$route.path.includes("papers")
        ? "CABINET_PAPER"
        : this.selectedType;
      // for (let project of this.projects) {
      //   if (project.year === this.selectedYear || this.selectedYear === "ALL") {
      //     res = [
      //       ...res,
      //       ...project.projects.filter((project) => {
      //         return (
      //           (this.selectedSector == "ALL" || this.selectedSector == ""
      //             ? true
      //             : project.sector === this.selectedSector) &&
      //           (this.selectedStatus == "ALL" || this.selectedStatus == ""
      //             ? true
      //             : project.status === this.selectedStatus)
      //         );
      //       }),
      //     ];
      //   }
      // }
      for (const yearObj of this.projects) {
        if (yearObj.year === this.selectedYear || this.selectedYear === "ALL")
          for (const sector of this.selectedSector == "ALL" ||
          this.selectedSector == ""
            ? yearObj.sectors
            : yearObj.sectors.filter(
                (sector) => sector.sectorId === this.selectedSector
              )) {
            const projects = this.$route.path.includes("closed")
              ? sector.projects.filter(
                  (project) => project.status === "COMPLETED"
                )
              : this.$route.path.includes("papers")
              ? this.selectedStatus == "ALL" || this.selectedStatus == ""
                ? sector.projects
                : sector.projects.filter(
                    (project) => project.status === this.selectedStatus
                  )
              : sector.projects.filter(
                  (project) => project.status !== "COMPLETED"
                );
            for (const i in projects) {
              if (
                (type == "ALL" || projects[i].type === type) &&
                (this.selectedCluster == "ALL" ||
                  this.selectedCluster == "" ||
                  projects[i].sector.cluster.id == this.selectedCluster)
              )
                res.push({
                  ...projects[i],
                  completed:
                    i == 0
                      ? projects.filter(
                          (project) => project.status === "COMPLETED"
                        ).length
                      : 0,
                  total: i == 0 ? projects.length : 0,
                  rowspan: i == 0 ? projects.length : 0,
                });
            }
          }
      }

      return res;
    },
  },

  async created() {
    this.fields = await this.getFields("projects_table");
    await this.getProjects();
    if (this.$route.query.project) {
      this.preselectProject(this.$route.query.project);
    }
  },
};
</script>

<style lang="scss">
#project-listing {
  // table {
  //   width: 97%;
  //   border-collapse: collapse;
  // }
  .search-area {
    background: url("/icons/search.svg");
    background-position: 10rem 8px;
    background-repeat: no-repeat;
    background-color: white;
  }

  .ON_TRACK {
    background-color: #00b300;
    color: white;
  }
  .ON_WATCH {
    background-color: #ff9900;
    color: white;
  }
  .OFF_TRACK {
    background-color: #ff0000;
    color: white;
  }

  // table {
  //   border-collapse: separate;
  //   border-spacing: 0 15px;
  // }
  .title {
    font-weight: 600;
  }
  .header {
    background: white;
    height: 3.5rem;
  }
  .names {
    margin: 0 3rem 0 -8rem;
  }
  td.description span {
    font-size: 11.5px !important;
    //&.short{
    //  display: none;
    //}
    //&.long:hover + &.short{
    //  display: initial;
    //}
    //&.short:hover + &.short{
    //  display: none;
    //}
  }
  .description {
    .long {
      display: none;
    }

    .short:hover + .long {
      display: initial;
    }
    //.short:hover + .dots {
    //  display: none;
    //}
  }
  // @media only screen and (max-width: 760px),
  //   (min-device-width: 250px) and (max-device-width: 640px) {
  //   table,
  //   thead,
  //   tbody,
  //   th,
  //   td,
  //   tr {
  //     display: block;
  //   }
  //   thead tr {
  //     position: absolute;
  //     top: -9999px;
  //     left: -9999px;
  //   }
  //   tr {
  //     background: white;
  //     width: 20rem;
  //     margin: 1rem 0 0 0;
  //     box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  //   }

  //   td {
  //     border: none;
  //     position: relative;
  //     padding-left: 50%;
  //   }

  //   td:before {
  //     position: absolute;
  //     top: 6px;
  //     left: 6px;
  //     width: 45%;
  //     padding-right: 10px;
  //     white-space: nowrap;
  //     font-size: 12px;
  //     text-align: justify;
  //     color: #000000;
  //     padding: 1rem;
  //   }

  //   td:nth-of-type(2):before {
  //     content: "Document";
  //   }
  //   td:nth-of-type(3):before {
  //     content: "Institution";
  //   }
  //   td:nth-of-type(4):before {
  //     content: "Date of Submission";
  //   }
  //   td:nth-of-type(5):before {
  //     content: "CLuster";
  //   }
  //   td:nth-of-type(6):before {
  //     content: "Status";
  //   }
  //   td:nth-of-type(7):before {
  //     content: "Actions";
  //   }
  // }
}
</style>
